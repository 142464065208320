<template>
  <v-card
    class="mx-auto mt-8 window"
    max-width="900"
    v-if="open"
    style="position:initial"
  >
  <WindowBar title="Projects" @close="$emit('close')" @maximize="maximized=true" @minimize="maximized=false"/>
  <div v-if="maximized">
  <v-card-text>
      <v-row
        align="center"
        justify="center"
      >
        <v-btn-toggle
          v-model="toggle_exclusive"
          multiple
          group
        >
          <v-btn retain-focus-on-click v-for="icon in buttonIcons" icon :key="icon" >
            <v-icon contain class="window">{{icon}}</v-icon>
          </v-btn>
        
        </v-btn-toggle>
      </v-row>
    </v-card-text>
    <v-container fluid>
      <v-row >
        <v-col
          class="mx-auto"
          v-for="card in filteredProjects"
          :key="card.id"
          :cols="card.flex"
        >
            <ProjectCard class="window" v-bind:card="card"/>
        </v-col>
      </v-row>
    </v-container>
    </div>
  </v-card>
</template>
<script>
import ProjectCard from './ProjectCard.vue'
import WindowBar from './WindowBar.vue'
  export default {
    data: () => ({
      buttonIcons : ['mdi-language-python','mdi-language-java','mdi-language-c','mdi-language-javascript'],
      cards: [
        { 
          title: 'Oliver\'s Room', subtitle: 'an arcade style platformer implemented using PyGame',
          src: require('@/assets/oliversrooms.png'),
          flex: 6,
          details: 'WORK IN PROGRESS: Oliver\'s Room is an arcade style platformer game including multiple levels, enemies, and consumables. The game was implemented in Python using the PyGame module',
          tag: 0,
          actions: [
            {title:'GitHub', link:'https://github.com/oliverclark15/arcade-game'},
          ],
          id : 0,
        },
        {
          title: 'Capstone Research Paper', subtitle: 'Authored "Can Duplicate Posts on Stack Overflow Benefit the Software Development Community?" an original research paper alongside fellow McGill undergraduates, graduatates, and a professor.',
          src: require('@/assets/msr1.png'),
          flex: 6, 
          details: 'Authored an original paper on duplicate questions on popular developer Q&A site Stack Overflow. The paper was selected for publication at the IEEE/ACM International Conference on Mining Software Repositories. Finally, the paper won the Best Student Presentation award at the conference. ',
          tag: 0,
          actions: [
            {title:'PDF', link:'http://rebels.ece.mcgill.ca/papers/msr2019_abric.pdf'},
            {title:'IEEE', link:''},
          ],
          id : 1,
        },
        { 
          title: 'Tablut Agent',
          subtitle: 'A Tablut (Viking Chess) game playing agent written in Java',
          src:  require('@/assets/Tablut_board.svg.png'), 
          flex: 6, 
          details: 'Tablut agent implented using an Minimax search algorithm with alpha-beta pruning in Java. The agent performed in the top 20% of agents competing against 150+ classmates.',
          tag: 1,
          actions: [
            {title:'PDF', link:'/tablut_report.pdf'},
          ],
          id : 2,
        },
        { 
          title: 'Distributed RMS',
          subtitle: 'A distributed reservation system implemented in Java',
          src: require('@/assets/distributed.png'), 
          flex: 6, 
          details: 'A distributed reservation system to allow a user to reserve flights, cars, rooms, or some combination of the three. The system was implemented in Java over TCP, using strict 2-phase locking,  2-phase commits, and shadowing protocols.', 
          tag: 1, 
          actions: [
            {title:'GitHub', link:'https://github.com/metchel/Comp100000000Project'},
            {title:'PDF', link:'/512_report.pdf'}
          ],
          id : 3,
        },
        { 
          title: 'budgety',
          subtitle: 'An expense reporting web application written in JavaScript',
          src: require('@/assets/bud.jpg'), 
          flex: 6, 
          details: 'WORK IN PROGRESS:  A full stack budgeting web application using the MEVN (MongoDB, Express.js, Vue.js, Node.js) stack. This application allows users to track expenses and provides spending analytics and useful information to the user', 
          tag: 3, 
          actions: [
            {title:'PDF', link:'http://rebels.ece.mcgill.ca/papers/msr2019_abric.pdf'},
          ],
          id : 4,
        },
        { 
          title: 'Rendering System',
          subtitle: 'A rendering system built on top of TinyRender implemented in C++.',
          src: require('@/assets/cubebox.png'), 
          flex: 6, 
          details: 'Implemented several different path tracers, lighting models, GLSL shaders to produce brilliant outputs', 
          tag: 2, 
          actions: [
            
          ],
          id : 5,
        },
         { 
          title: 'Blueberry Hill Outdoor Center websites',
          subtitle: 'Developed websites for the BBH Outdoor Center including a site for their annual Goshen Gallop running race',
          src: require('@/assets/vtlake.jpg'), 
          flex: 6, 
          details: 'WORK IN PROGRESS: Websites implemented with a Vue frontend and Node backend for handling exterior API calls. Websites deployed using GH Pages and GH actions to perform builds upon new commits to repo', 
          tag: 3, 
          actions: [
            {title:'Goshen Gallop', link:'https://goshengallop.com'}
            
          ]
        },
      ],
      show: false,
      toggle_exclusive: [0,1,2,3],
      open: true,
      maximized: true,
    }),
    components: {
        ProjectCard,
        WindowBar
    },
    computed: {
        filteredProjects: function () {
            var tmp = [];
            this.cards.forEach(x => {
                
                if (this.toggle_exclusive.includes(x.tag)){
                    console.log(this.toggle_exclusive   )
                    tmp.push(x)
                }
            });
            return tmp;
        }
    },
    methods : {

    }
  }
</script>
<style scoped src="98.css">
.trythis {
  color: black !important;
  background-color: red !important;
}
</style>