<template>
<!-- <marquee> -->
       <v-card
        class="mx-auto mt-8 window"
        max-width="250"
        v-if="open"
        @click="$emit('toFront')"
        >   
        <WindowBar title="Celery Man" @close="$emit('close')"  @maximize="maximized=true" @minimize="maximized=false"/>
        <v-img class="mt-5 mb-5" style="display:block; margin:auto;" src="oli-gif-2.gif" lazy-src="lazyheadshot.jpg" width=225></v-img>
    </v-card>
<!-- </marquee> -->
</template>

<script>
import WindowBar from './WindowBar.vue'


export default {
  name: "Draggable",
  data() {
    return {
      screenX: 0,
      screenY: 0,
      open: true,
      maximized: true,
    };
  },
  components : {
      WindowBar,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="98.css">
</style>