<template>
    <div class="title-bar">
        <div class="title-bar-text">{{title}}</div>
        <div class="title-bar-controls">
            <button aria-label="Minimize" @click="minWindow"></button>
            <button aria-label="Maximize" @click="maxWindow"></button>
            <button aria-label="Close" @click="closeWindow"></button>
        </div>
    </div>
</template>

<script>
  export default {
    data: () => ({
      open: true,
      maximized: true,
    }),
    props: {
        title: String
    },
    methods : {
      closeWindow : function () {
        console.log("closing window")
        this.open = false
        this.$emit("close")
      },
      maxWindow : function() {
        console.log("maximizing window")
        this.maximized = true
        this.$emit("maximize")
      },
      minWindow : function () {
        console.log("minimizing window")
        this.maximized = false
        this.$emit("minimize")
      },

    }
  }
</script>

<style scoped src="98.css">

</style>