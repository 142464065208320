<template>
  <v-card
    class="mx-auto mt-8 window"
    max-width="900"
    min-width="150"
    v-if="open"
  >
  <WindowBar title="Contact me!" @close="$emit('close')"  @maximize="maximized=true" @minimize="maximized=false"/>
  <v-container fluid v-if="maximized">
  <div class="window-body">
     <v-row class="text-center">
                <v-col class="mb-4" cols="12">
                    <v-btn class="mx-2"  small 
                        href="https://linkedin.com/in/oliverclark15" target="_blank">
                        LinkedIn <v-icon small>mdi-linkedin</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="mb-4" cols="12">
                    <v-btn class="mx-2" small 
                        href="https://github.com/oliverclark15" target="_blank">
                        GitHub <v-icon small>mdi-github</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="mb-4" cols="12">
                    <v-btn class="mx-2" small
                        href="mailto:oliverclark15@gmail.com">
                        Email <v-icon small>mdi-email</v-icon>  
                    </v-btn>
                </v-col>
            </v-row>
  </div>
    </v-container>
  </v-card>
</template>

<script>
  import WindowBar from './WindowBar.vue'
  export default {
    data: () => ({
      open: true,
      maximized: true,
    }),
    methods : {
    },
    components : {
        WindowBar
    }
  }
</script>
<style scoped src="98.css">

</style>