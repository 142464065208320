<template>
  <v-card
    class="mx-auto mt-8"
    max-width="344"
  >
  <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            ></v-img>

    <v-card-title class="text">
      {{card.title}}
    </v-card-title>

    <v-card-subtitle>
      {{card.subtitle}}
    </v-card-subtitle>
    <v-card-actions>
      <v-btn
        color="#356859"
        text
        v-for="action in card.actions"
        :key="action" 
        :href="action.link" target="_blank"
      >
       {{action.title}}
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          {{card.details}}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
        show: false,
    }),
    props: {
        card: Object
    }
  }
</script>
<style scoped>
.text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display:block;
  width:100%;
  min-width:1px;
}
</style>