<template>
  <v-card class="window" style="display: grid; grid-template-columns: 1fr; grid-template-rows:1fr" max-height="400px">
    <marquee
      scrollamount="12"
      style="
        grid-row-start: 1;
        grid-column-start: 1;
        text-shadow: 2px 2px
      "
      behavior="alternate"
      ><h5>oliver clark - software engineer</h5>
    </marquee>
    <marquee
      scrollamount="8"
      style="
        grid-row-start: 1;
        grid-column-start: 1;
        text-shadow: 2px 2px orange;
        font-style: italic;
        padding: 75px
      "
      behavior="alternate"
      ><h2>oliver clark - software engineer</h2></marquee
    >
    <marquee
      scrollamount="15"
      style="
        text-shadow: 2px 2px red;
        font-style: oblique;
        grid-row-start: 1;
        grid-column-start: 1;
        padding: 20px
      "
      behavior="alternate"
      direction="right"
      ><h4>oliver clark - software engineer</h4></marquee
    >
    <!-- <marquee style="position: relative;" scrollamount=1 behavior="alternate" onmousedown="this.stop();" onmouseup="this.start();">a</marquee> -->
    <marquee
      scrollamount="19"
      style="
        grid-row-start: 1;
        grid-column-start: 1;
        text-shadow: 2px 2px;
        padding: 100px
      "
      behavior="alternate"
      direction="right"
      ><h5>oliver clark - software engineer</h5>
    </marquee>
    <marquee
      scrollamount="14"
      behavior="alternate"
      style="
        grid-row-start: 1;
        grid-column-start: 1;
        padding: 50px;
        text-shadow: 2px 2px cyan
      "
      ><h3>oliver clark - software engineer</h3></marquee>

    <v-img
      src="title.png"
      lazy-src="title.png"
      max-height="200"
      contain
      style="
        grid-row-start: 1;
        grid-column-start: 1;
      "
    ></v-img>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
      v-on:change="$emit('tabchange', tab)"
    >
      <v-tab
        v-for="item in items"
        :key="item"
        class="window"
        v-on:click="$emit('tabclick', items.indexOf(item))"
        >{{ item }}</v-tab
      >
    </v-tabs>
  </v-card>
</template>``
<script>
export default {
  name: "Header",
  components: {},
  data: () => ({
    isActive: "OC",
    tab: 0,
    items: ["About", "Projects", "Contact"],
  }),
  props: ["tabs"],
};
</script>
<style scoped src="98.css">
.active {
  color: wheat;
  text-decoration: none;
}
.not-active {
  color: white;
}
.link-style {
  text-decoration: none;
}
.basil {
  background-color: #356859 !important;
}
.basil--text {
  color: !important;
}
.font {
  font-family: "Passion One" !important;
}
.big {
  font-size: 60 !important;
}
</style>