<template>
  <v-app>
    <HeaderNE v-on:tabclick="update" v-on:tabchange="update" />
    <v-content style="background-color:	#008080">
      <ProjectsNE v-on:close="tab = -1" v-if="tab === 1" />
      <ContactNE v-on:close="tab = -1" v-if="tab === 2" class="draggable" />
      <AboutNE
        v-on:toFront="aboutToFront()"
        v-on:close="tab = -1"
        v-if="tab === 0"
        v-bind:class="[aboutFront ? 'front' : 'back']"
        class="draggable"
      />
      <ProfileNE
        v-on:toFront="profileToFront()"
        v-on:close="tab = -1"
        v-if="tab === 0"
        v-bind:class="[profileFront ? 'front' : 'back']"
        class="draggable"
      />
      <router-view/>
    </v-content>

  </v-app>
</template>

<script>
import AboutNE from "./views/AboutNE";
import ContactNE from "./views/ContactNE";
import ProjectsNE from "./views/ProjectsNE";
import HeaderNE from "./views/HeaderNE";
import ProfileNE from "./views/ProfileNE";
import interact from "interactjs";

export default {
  name: "App",

  components: {
    AboutNE,
    ContactNE,
    ProjectsNE,
    HeaderNE,
    ProfileNE
  },

  data: () => ({
    tab: 0,
    about_open: true,
    projects_open: false,
    contact_open: false,
    theme: 1,
    aboutFront: true,
    profileFront: false
  }),
  methods: {
    update: function(a) {
      console.log(a);
      this.tab = a;
    },
    profileToFront: function() {
      console.log("setting profile to front");
      this.profileFront = true;
      this.aboutFront = false;
    },
    aboutToFront: function() {
      console.log("setting about to front");
      this.profileFront = false;
      this.aboutFront = true;
    },
    changeTheme: function(a) {
      this.theme = a;
      this.update(0);
    },
    initInteract: function() {
      interact(".item").draggable({
        listeners: { move: window.dragMoveListener },
        inertia: false,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "parent",
            endOnly: true
          })
        ]
      });
    }
  },
  mounted: function() {
    let aD = this.$refs.aboutDraggable;
    let cD = this.$refs.contactDraggable;
    let pD = this.$refs.projectsDraggable;
    console.log("Looking to hire me? hit me up at oliver@oliverclark.org");
    console.log(
      `  ____  _      _______      ________ _____   _____ _               _____  _  __
 / __ \\| |    |_   _\\ \\    / /  ____|  __ \\ / ____| |        /\\   |  __ \\| |/ /
| |  | | |      | |  \\ \\  / /| |__  | |__) | |    | |       /  \\  | |__) | ' / 
| |  | | |      | |   \\ \\/ / |  __| |  _  /| |    | |      / /\\ \\ |  _  /|  <  
| |__| | |____ _| |_   \\  /  | |____| | \\ \\| |____| |____ / ____ \\| | \\ \\| . \\ 
 \\____/|______|_____|   \\/   |______|_|  \\_\\\\_____|______/_/    \\_\\_|  \\_\\_|\\_\\
 `
    );
    this.initInteract(aD);
    this.initInteract(cD);
    this.initInteract(pD);
  }
};
interact(".draggable").draggable({
  // enable inertial throwing
  inertia: true,
  // keep the element within the area of it's parent
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: "parent",
      endOnly: true
    })
  ],
  // enable autoScroll
  autoScroll: true,

  listeners: {
    // call this function on every dragmove event
    move: dragMoveListener,

    // call this function on every dragend event
    end(event) {
      var textEl = event.target.querySelector("p");
      console.log("drag");
      textEl &&
        (textEl.textContent =
          "moved a distance of " +
          Math.sqrt(
            (Math.pow(event.pageX - event.x0, 2) +
              Math.pow(event.pageY - event.y0, 2)) |
              0
          ).toFixed(2) +
          "px");
    }
  }
});

function dragMoveListener(event) {
  var target = event.target;
  console.log(event);
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

  // translate the element
  target.style.webkitTransform = target.style.transform =
    "translate(" + x + "px, " + y + "px)";
  "translate(" + x + "px, " + y + "px)";
  console.log("translate(" + x + "px, " + y + "px)");
  // update the posiion attributes
  target.setAttribute("data-x", x);
  target.setAttribute("data-y", y);
}

// this function is used later in the resizing and gesture demos
window.dragMoveListener = dragMoveListener;
</script>

<style scoped>
.active {
  color: wheat;
  text-decoration: none;
}
.not-active {
  color: white;
}
.link-style {
  text-decoration: none;
}
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
.font {
  font-family: "Passion One";
}
.draggable {
  touch-action: none;
}
.front {
  z-index: 9999
}
.back {
  z-index: 0
}
</style>
