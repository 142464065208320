<template>
  <v-card
    @click="$emit('toFront')"
    class="mx-auto mt-8 window"
    max-width="500"
    min-width="225"
    v-if="open"
    color="#c0c0c0"
  >
    <WindowBar
      title="A brief history of Oliver"
      @close="$emit('close')"
      @maximize="maximized=true"
      @minimize="maximized=false"
    />
    <Timeline v-if="maximized" />
  </v-card>
</template>

<script>
import WindowBar from "./WindowBar.vue";
import Timeline from "./Timeline.vue";

export default {
  name: "AboutNE",
  data() {
    return {
      screenX: 0,
      screenY: 0,
      open: true,
      maximized: true
    };
  },
  components: {
    WindowBar,
    Timeline
  }
};
</script>

<style src="98.css">
/* #drag-1 {
  width: 25%;
  min-height: 6.5em;
  margin: 10%;
  background-color: #29e;
  color: white;
  border-radius: 0.75em;
  padding: 4%;
  touch-action: none;
  user-select: none;
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
} */
</style>